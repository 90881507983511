
import { Component, Vue } from 'vue-property-decorator';
import Header from '@/components/Header.vue';
@Component({
  components: {
    Header
  }
})
export default class GiveawayIndex extends Vue {
}
